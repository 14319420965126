import {
  DocumentData,
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  limit as firestoreLimit,
  getDoc,
  getDocs,
  increment,
  orderBy,
  query,
  setDoc,
  startAfter,
  updateDoc,
  where,
} from 'firebase/firestore'
import { StoryData, convertReadingLevelToNumber } from '../types'
import { analytics, db } from '../firebase'

import { User } from 'firebase/auth'
import { logEvent } from 'firebase/analytics'

export async function generateStoryWithImages(
  numPages: number,
  animal: string,
  lesson: string,
  userId: string,
  readingLevel: string | null
): Promise<{ storyId: string }> {
  // Save the initial story parameters to Firestore
  const storyId = await saveStoryToFirestore(
    numPages,
    animal,
    lesson,
    userId,
    readingLevel
  )
  return { storyId }
}

export async function saveStoryToFirestore(
  numPages: number,
  animal: string,
  lesson: string,
  userId: string,
  readingLevel: string | null
): Promise<string> {
  try {
    const docRef = await addDoc(collection(db, 'stories'), {
      numPages,
      animal,
      lesson,
      createdAt: new Date(),
      status: 'pending',
      creatorId: userId,
      readingLevel: readingLevel || 'none',
    })
    console.log('Story parameters saved successfully with ID:', docRef.id)
    return docRef.id
  } catch (error) {
    console.error('Error saving story parameters to Firestore:', error)
    throw new Error(
      'Failed to save story parameters: ' + (error as Error).message
    )
  }
}

export async function getStoryFromFirestore(
  storyId: string
): Promise<StoryData> {
  try {
    const docRef = doc(db, 'stories', storyId)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      const data = docSnap.data()
      data.lesson = data.updatedLesson || data.lesson
      return {
        id: storyId,
        ...data,
      } as StoryData
    } else {
      throw new Error('No such document!')
    }
  } catch (error) {
    console.error('Error fetching story from Firestore:', error)
    throw new Error('Failed to fetch story: ' + (error as Error).message)
  }
}

export async function toggleLikeStory(
  storyId: string,
  user: User
): Promise<void> {
  try {
    const userRef = doc(db, 'users', user.uid)
    const storyRef = doc(db, 'stories', storyId)
    const userDoc = await getDoc(userRef)
    const storyDoc = await getDoc(storyRef)

    if (!storyDoc.exists()) {
      throw new Error("shouldn't happen")
    }

    let setLiked = true

    if (userDoc.exists()) {
      const userData = userDoc.data()
      const likedStories = userData.likedStories || []

      if (likedStories.includes(storyId)) {
        // Unlike the story
        setLiked = false
        await updateDoc(userRef, {
          likedStories: arrayRemove(storyId),
        })
      } else {
        // Like the story
        await updateDoc(userRef, {
          likedStories: arrayUnion(storyId),
        })
      }
    } else if (!userDoc.exists()) {
      // If the user document doesn't exist, create it
      await setDoc(userRef, {
        likedStories: [storyId],
        email: user.email,
      })
    }

    await updateDoc(storyRef, {
      likedUsers: setLiked ? arrayUnion(user.uid) : arrayRemove(user.uid),
      likedCount: setLiked ? increment(1) : increment(-1),
    })
  } catch (error) {
    console.error('Error toggling like:', error)
    throw new Error('Failed to toggle like: ' + (error as Error).message)
  }
}

export async function getStoryLikeStatus(
  storyId: string,
  userId: string
): Promise<boolean> {
  try {
    const userRef = doc(db, 'users', userId)
    const userDoc = await getDoc(userRef)

    if (userDoc.exists()) {
      const userData = userDoc.data()
      const likedStories = userData.likedStories || []
      return likedStories.includes(storyId)
    }

    return false
  } catch (error) {
    console.error('Error getting story like status:', error)
    throw new Error(
      'Failed to get story like status: ' + (error as Error).message
    )
  }
}

export const getPublishedStories = async (
  lastDoc: DocumentData | null = null,
  limitCount: number = 10,
  userId: string | null = null,
  likedOnly: boolean = false,
  myStoriesOnly: boolean = false,
  sortOption: string = 'newest'
) => {
  const storiesCollection = collection(db, 'stories')

  let likedStories: string[] = []
  if (userId) {
    const userRef = doc(db, 'users', userId)
    const userDoc = await getDoc(userRef)
    if (userDoc.exists()) {
      likedStories = userDoc.data().likedStories || []
    }
  }

  if (likedOnly && likedStories.length === 0) {
    return { stories: [], lastVisible: null }
  }

  let q

  if (likedOnly) {
    q = query(
      storiesCollection,
      where('__name__', 'in', likedStories),
      where('status', '==', 'ready')
    )
  } else if (myStoriesOnly && userId) {
    q = query(
      storiesCollection,
      where('creatorId', '==', userId),
      where('status', '==', 'ready')
    )
  } else {
    q = query(storiesCollection, where('status', '==', 'ready'))
  }

  // Apply sorting
  if (sortOption === 'most-liked') {
    q = query(q, orderBy('likedCount', 'desc'), orderBy('createdAt', 'desc'))
  } else {
    q = query(q, orderBy('createdAt', 'desc'))
  }

  if (lastDoc && !likedOnly) {
    q = query(q, startAfter(lastDoc))
  }
  q = query(q, firestoreLimit(limitCount))

  try {
    const querySnapshot = await getDocs(q)

    let stories = querySnapshot.docs.map((doc) => {
      const data = doc.data()

      return {
        id: doc.id,
        animal: data.animal,
        title: data.title,
        lesson: data.updatedLesson || data.lesson,
        coverImageUrl:
          data.pages && data.pages[0] ? data.pages[0].imageUrl : null,
        isLiked: likedStories.includes(doc.id),
        createdAt: data.createdAt.toDate(),
        readingLevel: convertReadingLevelToNumber(data.readingLevel),
        likeCount: data.likedUsers ? data.likedUsers.length : 0,
      }
    })

    if (likedOnly) {
      const startIndex = lastDoc
        ? stories.findIndex((story) => story.id === lastDoc.id) + 1
        : 0
      stories = stories.slice(startIndex, startIndex + limitCount)
    }

    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1]
    return { stories, lastVisible }
  } catch (error) {
    console.error('Error in getPublishedStories:', error)
    throw error
  }
}

// Add this function to log events
export const logAnalyticsEvent = (
  eventName: string,
  eventParams?: { [key: string]: any } /* eslint-disable-line */,
) => {
  logEvent(analytics, eventName, eventParams)
}

export const checkIsAdmin = async (userId: string): Promise<boolean> => {
  const adminRef = doc(db, 'admin', userId)
  const adminDoc = await getDoc(adminRef)
  return adminDoc.exists()
}

export async function getRandomStoryId(): Promise<string | null> {
  try {
    const storiesCollection = collection(db, 'stories')
    const readyStoriesQuery = query(
      storiesCollection,
      where('status', '==', 'ready'),
      orderBy('createdAt', 'desc')
    )

    const querySnapshot = await getDocs(readyStoriesQuery)

    if (querySnapshot.empty) {
      console.log('No stories found')
      return null
    }

    const storyIds = querySnapshot.docs.map((doc) => doc.id)
    const randomIndex = Math.floor(Math.random() * storyIds.length)
    return storyIds[randomIndex]
  } catch (error) {
    console.error('Error fetching random story:', error)
    throw new Error('Failed to fetch random story: ' + (error as Error).message)
  }
}
