import './StoryBook.css'

import { LANGUAGES, Language } from '../constants/languages'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { StoryData, StoryPage } from '../types'
import {
  faCompress,
  faExpand,
  faGlobeAmericas,
  faGlobeAsia,
  faRedo,
  faVolumeMute,
  faVolumeUp,
} from '@fortawesome/free-solid-svg-icons'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TheEndPage from './TheEndPage'
import TitlePage from './TitlePage'
import fscreen from 'fscreen'
import { logAnalyticsEvent } from '../services/storyGenerationService'
import { useAdminStatus } from '../hooks/useAdminStatus'

interface StoryBookProps {
  storyId: string
  story: StoryData
  fetchStory: () => Promise<void>
}

const StoryPageComponent: React.FC<{
  page: StoryPage
  pageNumber: number
  totalPages: number
  currentLanguage: string
}> = ({ page, pageNumber, totalPages, currentLanguage }) => {
  return (
    <>
      <div className="page-content-container">
        <img src={page.imageUrl} alt={`Page ${pageNumber}`} draggable="false" />
        <p className="page-content">
          {currentLanguage === 'en' ? page.text : page[currentLanguage]}
        </p>
      </div>
      <div className="page-number">{`${pageNumber} of ${totalPages}`}</div>
    </>
  )
}

const StoryBook: React.FC<StoryBookProps> = ({
  story,
  storyId,
  fetchStory,
}) => {
  const navigate = useNavigate()
  const params = useParams<{ pageNumber: string }>()
  const [searchParams, setSearchParams] = useSearchParams()
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [isAudioOn, setIsAudioOn] = useState(false)
  const { isAdmin } = useAdminStatus()
  const [isRegenerating, setIsRegenerating] = useState(false)
  const functions = getFunctions()
  const [currentLanguage, setCurrentLanguage] = useState(() => {
    return searchParams.get('lang') || 'en'
  })
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false)
  const [isTranslating, setIsTranslating] = useState(false)
  const [isGeneratingAudio, setIsGeneratingAudio] = useState(false)
  const audioRef = useRef<HTMLAudioElement | null>(null)

  const pageNumber = parseInt(params.pageNumber || '0')

  useEffect(() => {
    const className = 'disable-touch-actions'
    document.body.classList.add(className)
    return () => {
      document.body.classList.remove(className)
    }
  }, [])

  const [dragOffset, setDragOffset] = useState(0)
  const [isTransitioning, setIsTransitioning] = useState(false)
  const dragStartX = useRef<number | null>(null)
  const pages = story.pages
  const title = story.title
  const subtitle = story.lesson

  const handleSwipe = useCallback(
    (direction: 'left' | 'right') => {
      setIsTransitioning(true)
      setTimeout(() => {
        if (direction === 'left' && pageNumber < totalPageCount - 1) {
          const newPage = pageNumber + 1
          if (storyId) {
            navigate(`/story/${storyId}/${newPage}${window.location.search}`)
          }
        } else if (direction === 'right' && pageNumber > 0) {
          const newPage = pageNumber - 1
          if (storyId) {
            navigate(`/story/${storyId}/${newPage}${window.location.search}`)
          }
        }
        setDragOffset(0)
        setIsTransitioning(false)
      }, 300)
    },
    [pageNumber, pages.length, navigate, storyId]
  )

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft' && pageNumber > 0) {
        handleSwipe('right')
      } else if (event.key === 'ArrowRight' && pageNumber < pages.length + 1) {
        handleSwipe('left')
      }
    },
    [pageNumber, pages.length, handleSwipe]
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  const handleDragStart = (clientX: number) => {
    dragStartX.current = clientX
  }

  const handleDrag = (clientX: number) => {
    if (dragStartX.current !== null && !isTransitioning) {
      const diff = clientX - dragStartX.current
      setDragOffset(diff)
    }
  }

  const handleDragEnd = () => {
    if (Math.abs(dragOffset) > 40 && !isTransitioning) {
      handleSwipe(dragOffset > 0 ? 'right' : 'left')
    } else if (!isTransitioning) {
      setDragOffset(0)
    }
    dragStartX.current = null
  }

  const getNextPage = () => {
    if (dragOffset < 0 && pageNumber < pages.length) {
      return pageNumber + 1
    } else if (dragOffset > 0 && pageNumber > 0) {
      return pageNumber - 1
    }
    return null
  }

  const nextPage = getNextPage()

  if (pages.length === 0) {
    return <div>No pages to display</div>
  }

  const renderPage = (pageIndex: number) => {
    if (pageIndex === 0 && title) {
      return (
        <TitlePage
          title={title}
          subtitle={subtitle}
          translatedTitle={
            currentLanguage !== 'en'
              ? story[`title-${currentLanguage}`]
              : undefined
          }
          translatedSubtitle={
            currentLanguage !== 'en'
              ? story[`lesson-${currentLanguage}`]
              : undefined
          }
        />
      )
    }
    if (pageIndex === totalPageCount - 1) {
      return <TheEndPage storyId={storyId || ''} title={title || ''} />
    }
    const adjustedIndex = title ? pageIndex - 1 : pageIndex
    if (adjustedIndex >= 0 && adjustedIndex < pages.length) {
      return (
        <StoryPageComponent
          page={pages[adjustedIndex]}
          pageNumber={adjustedIndex + 1}
          totalPages={pages.length}
          currentLanguage={currentLanguage}
        />
      )
    }
    return null
  }

  const toggleFullscreen = () => {
    if (!fscreen.fullscreenElement) {
      fscreen.requestFullscreen(document.documentElement)
      setIsFullscreen(true)
    } else {
      fscreen.exitFullscreen()
      setIsFullscreen(false)
    }
  }

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!fscreen.fullscreenElement)
    }

    fscreen.addEventListener('fullscreenchange', handleFullscreenChange)
    return () => {
      fscreen.removeEventListener('fullscreenchange', handleFullscreenChange)
    }
  }, [])

  // Adjust the page count to include the title page and end page
  const totalPageCount = title ? pages.length + 2 : pages.length + 1

  const generateAudioForStory = useCallback(async () => {
    if (!storyId) return
    if (isGeneratingAudio) return
    setIsGeneratingAudio(true)
    try {
      const generateAudioFunction = httpsCallable(
        functions,
        'generateAudioForStory'
      )
      await generateAudioFunction({
        storyId,
        language: currentLanguage === 'zh_py' ? 'zh' : currentLanguage,
      })
      await fetchStory() // Refresh the story data to get the new audio URLs
      logAnalyticsEvent('generate_audio', { storyId })
    } catch (error) {
      console.error('Error generating audio:', error)
      alert(`Oops, something went wrong: ${error}`)
    } finally {
      setIsGeneratingAudio(false)
    }
  }, [storyId, fetchStory])

  const playAudio = (audioUrl: string) => {
    if (audioRef.current) {
      audioRef.current.src = audioUrl
      audioRef.current.play()
    } else {
      const audio = new Audio(audioUrl)
      audioRef.current = audio
      audio.play()
    }
  }

  const playCurrentPageAudio = () => {
    const language = currentLanguage === 'zh_py' ? 'zh' : currentLanguage
    if (pageNumber === 0) {
      playAudio(story[`${language}TitleAudioUrl`])
      return
    } else if (pageNumber > pages.length) {
      return
    }
    const currentPageData = pages[pageNumber - 1]
    const audioUrl = currentPageData[`${language}AudioUrl`] as string
    playAudio(audioUrl)
  }

  useEffect(() => {
    if (isAudioOn) {
      playCurrentPageAudio()
    }
  }, [story])

  useEffect(() => {
    const lang = currentLanguage === 'zh_py' ? 'zh' : currentLanguage
    if (isAudioOn && !isGeneratingAudio) {
      if (!story[`${lang}TitleAudioUrl`]) {
        generateAudioForStory()
        return
      }
      playCurrentPageAudio()
    } else if (!isAudioOn && audioRef.current) {
      audioRef.current.pause()
    }
  }, [isAudioOn])

  useEffect(() => {
    if (isAudioOn) {
      if (audioRef.current) {
        audioRef.current.pause()
      }
      playCurrentPageAudio()
    }
  }, [pageNumber])

  const toggleSpeech = () => {
    if (isGeneratingAudio) return

    setIsAudioOn(!isAudioOn)
    logAnalyticsEvent('toggle_speech', {
      storyId,
      isSpeaking: !isAudioOn,
    })
  }

  const handleRegenerate = async () => {
    if (!storyId) return
    setIsRegenerating(true)
    try {
      const regenerateImageFunction = httpsCallable(
        functions,
        'regenerateImage'
      )

      await regenerateImageFunction({
        storyId,
        pageNumber: pageNumber,
      })

      logAnalyticsEvent('regenerate_image', {
        storyId,
        pageNumber: pageNumber,
      })

      // refresh the page
      window.location.reload()
    } catch (error) {
      console.error('Error regenerating image:', error)
      // Handle error (e.g., show an error message to the user)
    } finally {
      setIsRegenerating(false)
    }
  }

  const handleTranslate = async (languageIn: string) => {
    const translateStoryFunction = httpsCallable(functions, 'translateStory')

    const language = languageIn === 'zh_py' ? 'zh' : languageIn
    setIsTranslating(true)
    try {
      await translateStoryFunction({
        storyId: story.id,
        targetLanguage: language,
      })

      logAnalyticsEvent('translate_story', {
        storyId: story.id,
        targetLanguage: language,
      })

      fetchStory()
    } catch (error) {
      console.error('Error translating story:', error)
      alert('Failed to translate story. Please try again later.')
    } finally {
      setIsTranslating(false)
    }
  }

  const handleLanguageChange = async (lang: Language) => {
    setIsLanguageMenuOpen(false)
    setIsAudioOn(false)
    setCurrentLanguage(lang.code)
    setSearchParams({ lang: lang.code })

    if (lang.code === 'en' || `title-${lang.code}` in story) {
      logAnalyticsEvent('toggle_language', {
        storyId,
        language: lang.code,
        translationNeeded: false,
      })
      return
    }
    await handleTranslate(lang.code)
    logAnalyticsEvent('toggle_language', {
      storyId,
      language: lang.code,
      translationNeeded: true,
    })
  }

  const toggleLanguageMenu = () => {
    setIsLanguageMenuOpen(!isLanguageMenuOpen)
  }

  return (
    <div
      className="story-book relative"
      onTouchStart={(e) => handleDragStart(e.touches[0].clientX)}
      onTouchMove={(e) => handleDrag(e.touches[0].clientX)}
      onTouchEnd={handleDragEnd}
      onMouseDown={(e) => handleDragStart(e.clientX)}
      onMouseMove={(e) => dragStartX.current !== null && handleDrag(e.clientX)}
      onMouseUp={handleDragEnd}
      onMouseLeave={handleDragEnd}
      tabIndex={0}
    >
      <div className="absolute top-4 right-4 flex z-10 flex-col items-end">
        {pageNumber === 0 && ( // Only show tooltips on the Title page
          <>
            <div className="flex items-center mb-2">
              <span className="text-gray-800 font-medium px-2 py-1 rounded text-sm relative">
                Toggle audio
              </span>
              <button
                className="w-10 h-10 flex items-center justify-center bg-transparent hover:bg-transparent transition-none"
                onClick={toggleSpeech}
                disabled={isGeneratingAudio}
              >
                <FontAwesomeIcon
                  icon={isAudioOn ? faVolumeUp : faVolumeMute}
                  className={`w-6 h-6 text-gray-700 ${isGeneratingAudio ? 'animate-spin' : ''}`}
                />
              </button>
            </div>
            <div className="flex items-center mb-2">
              <span className="text-gray-800 font-medium px-2 py-1 rounded text-sm relative">
                Toggle fullscreen
              </span>
              <button
                className="w-10 h-10 flex items-center justify-center bg-transparent hover:bg-transparent transition-none"
                onClick={toggleFullscreen}
              >
                <FontAwesomeIcon
                  icon={isFullscreen ? faCompress : faExpand}
                  className="w-6 h-6 text-gray-700"
                />
              </button>
            </div>
            {isAdmin && (
              <div className="flex items-center mb-2">
                <span className="text-gray-800 font-medium px-2 py-1 rounded text-sm relative">
                  Regenerate image
                </span>
                <button
                  className="w-10 h-10 flex items-center justify-center bg-transparent hover:bg-transparent transition-none"
                  onClick={handleRegenerate}
                  disabled={isRegenerating}
                >
                  <FontAwesomeIcon
                    icon={faRedo}
                    className={`w-6 h-6 text-gray-700 bg-transparent ${isRegenerating ? 'animate-spin' : ''}`}
                  />
                </button>
              </div>
            )}
            <div className="flex items-center mb-2">
              <span
                className={`text-gray-800 font-medium px-2 py-1 rounded text-sm relative ${isLanguageMenuOpen ? 'hidden' : ''}`}
              >
                Change language
              </span>
              <div className="relative">
                <button
                  className="w-10 h-10 flex items-center justify-center bg-transparent hover:bg-transparent transition-none"
                  onClick={toggleLanguageMenu}
                  disabled={isTranslating}
                >
                  <FontAwesomeIcon
                    icon={isLanguageMenuOpen ? faGlobeAsia : faGlobeAmericas}
                    className={`w-6 h-6 text-gray-700 bg-transparent ${isTranslating ? 'animate-spin' : ''}`}
                  />
                </button>
                {isLanguageMenuOpen && !isTranslating && (
                  <>
                    {LANGUAGES.filter(
                      (lang) => lang.code !== currentLanguage
                    ).map((lang) => {
                      const Flag = lang.flag
                      return (
                        <button
                          key={lang.code}
                          className="w-10 h-10 flex items-center justify-center bg-transparent hover:bg-transparent transition-none"
                          onClick={() => handleLanguageChange(lang)}
                        >
                          <Flag />
                        </button>
                      )
                    })}
                  </>
                )}
              </div>
            </div>
          </>
        )}
        {pageNumber !== 0 && (
          <>
            <button
              className="w-10 h-10 flex items-center justify-center bg-transparent hover:bg-transparent transition-none"
              onClick={toggleSpeech}
              disabled={isGeneratingAudio}
            >
              <FontAwesomeIcon
                icon={isAudioOn ? faVolumeUp : faVolumeMute}
                className={`w-6 h-6 text-gray-700 ${isGeneratingAudio ? 'animate-spin' : ''}`}
              />
            </button>
            <button
              className="w-10 h-10 flex items-center justify-center bg-transparent hover:bg-transparent transition-none"
              onClick={toggleFullscreen}
            >
              <FontAwesomeIcon
                icon={isFullscreen ? faCompress : faExpand}
                className="w-6 h-6 text-gray-700"
              />
            </button>
            {isAdmin && (
              <button
                className="w-10 h-10 flex items-center justify-center bg-transparent hover:bg-transparent transition-none"
                onClick={handleRegenerate}
                disabled={isRegenerating}
              >
                <FontAwesomeIcon
                  icon={faRedo}
                  className={`w-6 h-6 text-gray-700 bg-transparent ${isRegenerating ? 'animate-spin' : ''}`}
                />
              </button>
            )}
            <div className="relative">
              <button
                className="w-10 h-10 flex items-center justify-center bg-transparent hover:bg-transparent transition-none"
                onClick={toggleLanguageMenu}
                disabled={isTranslating}
              >
                <FontAwesomeIcon
                  icon={isLanguageMenuOpen ? faGlobeAsia : faGlobeAmericas}
                  className={`w-6 h-6 text-gray-700 bg-transparent ${isTranslating ? 'animate-spin' : ''}`}
                />
              </button>
              {isLanguageMenuOpen && !isTranslating && (
                <>
                  {LANGUAGES.filter(
                    (lang) => lang.code !== currentLanguage
                  ).map((lang) => {
                    const Flag = lang.flag
                    return (
                      <button
                        key={lang.code}
                        className="w-10 h-10 flex items-center justify-center bg-transparent hover:bg-transparent transition-none"
                        onClick={() => handleLanguageChange(lang)}
                      >
                        <Flag />
                      </button>
                    )
                  })}
                </>
              )}
            </div>
          </>
        )}
      </div>
      {nextPage !== null && nextPage < totalPageCount && (
        <div
          className={`page next-page ${isTransitioning ? 'transitioning' : ''}`}
          style={{
            opacity: Math.abs(dragOffset) / 500,
          }}
        >
          {renderPage(nextPage)}
        </div>
      )}
      <div
        className={`page current-page ${
          isTransitioning ? 'transitioning' : ''
        }`}
        style={{
          transform: `translateX(${dragOffset}px) rotate(${
            dragOffset / 20
          }deg)`,
          opacity: 1 - Math.abs(dragOffset) / 500,
        }}
      >
        {renderPage(pageNumber)}
      </div>
    </div>
  )
}

export default StoryBook
